<template>
  <div>
    <!--		header base-->
    <app-header
      :config="config"
      v-if="config.nav_active === 1 && headerTemplate === 'base'"
    ></app-header>

    <!--		header santogal-->
    <app-header-santogal-renault
      :config="config"
      v-if="config.nav_active === 1 && headerTemplate === 'renault_santogal'"
    ></app-header-santogal-renault>

    <app-header-santogal-dacia
      :config="config"
      v-if="config.nav_active === 1 && headerTemplate === 'dacia_santogal'"
    ></app-header-santogal-dacia>

    <!--		header soauto vgrp-->
    <app-header-soauto-vgrp
      :config="config"
      v-if="config.nav_active === 1 && headerTemplate === 'soauto_vgrp'"
    ></app-header-soauto-vgrp>

    <!--		header soauto branco-->
    <app-header-soauto
      :config="config"
      v-if="config.nav_active === 1 && headerTemplate === 'soauto_vw_branco'"
    ></app-header-soauto>

    <!--		header soauto azul-->
    <app-header-soauto-azul
      :config="config"
      v-if="config.nav_active === 1 && headerTemplate === 'soauto_vw_azul'"
    ></app-header-soauto-azul>

    <div class="container-fluid container-top">
      <!--			normal lp-->
      <top-zone
        :config="config"
        :json-config="jsonConfig"
        :form-data="formData"
        :privacy-text="setPrivacyText()"
        :campaign-contract="campaignContract"
      ></top-zone>
    </div>

    <!--		phone cta button-->
    <div
      class="container-fluid phone-button-cta"
      id="phone-button-cta"
      v-if="jsonConfig.phone_button_cta !== undefined"
    >
      <phone_button_cta
        :json-config="jsonConfig.phone_button_cta"
      ></phone_button_cta>
    </div>

    <!--        cta -->
    <div
      class="container-fluid cta-bg pt-lg-3"
      id="cta2"
      v-if="jsonConfig.cta !== undefined"
    >
      <div class="row">
        <div
          class="col-12 mt-3 mt-md-3 cta mb-3 mb-md-3 text-center d-none d-lg-block"
        >
          <p>
            <span class="cta_text_1" v-html="jsonConfig.cta.text1"></span>
            <span class="cta_text_2" v-html="jsonConfig.cta.text2"></span>
          </p>
        </div>
      </div>
    </div>

    <!--        flipclock-->
    <div
      class="container-fluid pt-2 pb-2"
      id="countdown-clock"
      v-if="jsonConfig.countdown !== undefined && validateDate()"
    >
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-center">
            <flip-countdown
              :deadline="deadline"
              :labels="labels"
            ></flip-countdown>
          </div>
        </div>
      </div>
    </div>

    <!--        after form -->
    <div
      class="container-fluid container_after_form"
      id="before_form_slideshow"
      v-if="jsonConfig.container_after_form !== undefined"
      :class="{
        'd-none d-md-block':
          jsonConfig.container_after_form.active_top === true,
      }"
    >
      <div class="row">
        <div class="col-12">
          <div class="container">
            <div class="row">
              <div
                class="col-12 col-md-4 pb-3 pb-md-0"
                :class="{
                  'offset-md-4':
                    jsonConfig.container_after_form.bullets.length === 1,
                  'offset-md-2':
                    jsonConfig.container_after_form.bullets.length === 2 &&
                    i !== 1,
                  'col-md-6':
                    jsonConfig.container_after_form.size !== undefined &&
                    jsonConfig.container_after_form.size === 2,
                }"
                v-for="(item, i) of jsonConfig.container_after_form.bullets"
                :key="i"
                :index="i"
              >
                <div class="row">
                  <div class="col-3">
                    <img :src="config.bullets[i]" alt="" width="60px" />
                  </div>
                  <div class="col-9">
                    <h4>{{ item.title }}</h4>
                    <p>{{ item.text }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--        navigate-out -->
    <div
      class="container-fluid container-navigate-out"
      id="navigate-out-top"
      v-if="
        jsonConfig.navigate_out_zone !== undefined &&
        jsonConfig.navigate_out_zone.position === undefined
      "
    >
      <div class="container">
        <div class="row">
          <div
            class="col-12 d-md-flex justify-content-md-center text-center align-content-center"
          >
            <div
              class="pb-1 pt-1"
              v-html="jsonConfig.navigate_out_zone.text"
            ></div>
            <div class="pb-1 pt-2 pt-md-1 p-md-0 pl-0 pl-md-4">
              <a
                v-bind:href="jsonConfig.navigate_out_zone.href"
                target="_blank"
                rel="noopener"
                v-html="jsonConfig.navigate_out_zone.button_text"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container-fluid"
      style="padding: 0"
      v-if="config.free_html_zone !== null"
      v-html="config.free_html_zone"
    ></div>

    <!--       tabs static-content-->
    <div
      class="container-fluid container-tabbed-static-content"
      v-if="jsonConfig.tabbed_static_content !== undefined"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <tabbed-static-content
              :config="jsonConfig.tabbed_static_content"
            ></tabbed-static-content>
          </div>
        </div>
      </div>
    </div>

    <!--       tabs static-content-v2 -->
    <div
      class="container-fluid container-tabbed-static-content"
      v-if="jsonConfig.tabbed_static_content_v2 !== undefined"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <tabbed-static-content-v2
              :config="jsonConfig.tabbed_static_content_v2"
            ></tabbed-static-content-v2>
          </div>
        </div>
      </div>
    </div>
    <!-- Service Cars -->
    <div
      class="container-fluid container-tabbed-static-content"
      v-if="jsonConfig.products_cards !== undefined"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <products-cards
              :config="jsonConfig.products_cards"
              :products="config.products"
            ></products-cards>
          </div>
        </div>
      </div>
    </div>

    <!--       tabs static-content-v2 -->
    <div
      class="container-fluid container-tabbed-static-content"
      v-if="jsonConfig.tabbed_static_content_v3 !== undefined"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <tabbed-static-content-v3
              :config="jsonConfig.tabbed_static_content_v3"
            ></tabbed-static-content-v3>
          </div>
        </div>
      </div>
    </div>

    <!--       tabs static-content-v4 -->
    <div
      class="container-fluid container-tabbed-static-content"
      v-if="jsonConfig.products_cards_v2 !== undefined"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <products-cards-v2
              :config="jsonConfig.products_cards_v2"
              :products="config.products"
            ></products-cards-v2>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container-fluid container-tabbed-static-content"
      v-if="jsonConfig.products_cards_v4 !== undefined"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <products-cards-v4
              :config="jsonConfig.products_cards_v4"
              :products="config.products"
              :type="formData.form_placement"
            ></products-cards-v4>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container-fluid container-tabbed-static-content"
      v-if="jsonConfig.products_cards_v5 !== undefined"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <products-cards-v5
              :config="jsonConfig.products_cards_v5"
              :products="config.products"
              :type="formData.form_placement"
            ></products-cards-v5>
          </div>
        </div>
      </div>
    </div>

    <!-- Gallery v2 -->

    <div class="container-fluid container-gallery">
      <div
        class="container"
        v-if="
          jsonConfig.galleryv2 !== undefined &&
          jsonConfig.galleryv2.top !== undefined &&
          jsonConfig.galleryv2.top === true
        "
      >
        <div class="row mt-5" style="margin-left: 0; margin-right: 0">
          <div class="col-12">
            <h2
              class="text-center pb-3"
              v-html="
                jsonConfig.galleryv2.header !== undefined
                  ? jsonConfig.galleryv2.header
                  : 'Viaturas em destaque!'
              "
            ></h2>
            <gallery2
              :gallery-images="jsonConfig.galleryv2.images"
              :size="jsonConfig.galleryv2.size"
            ></gallery2>
            <div class="d-flex justify-content-center">
              <a
                class="btn btn-primary slideshow-btn"
                href="#form"
                v-html="jsonConfig.galleryv2.button"
                v-if="jsonConfig.galleryv2.button_active"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--        slideshow-->
    <div
      class="container-fluid container-slideshow"
      v-if="jsonConfig.slideshow !== undefined"
    >
      <div class="row">
        <slideshow
          :json-config="jsonConfig"
          :images="config.slideshow"
        ></slideshow>
      </div>
      <div class="d-flex justify-content-center">
        <a
          class="btn btn-primary slideshow-btn"
          href="#form"
          v-html="jsonConfig.slideshow.button"
        ></a>
      </div>
    </div>

    <!--        slideshow v2-->
    <div
      class="container-fluid container-slideshow"
      v-if="
        jsonConfig.slideshowv2 !== undefined &&
        jsonConfig.slideshowv2.length > 0
      "
    >
      <slideshow-v2 :json-config="jsonConfig"></slideshow-v2>
    </div>

    <!--        galleries-->
    <div class="container-fluid container-gallery">
      <div class="container" v-if="jsonConfig.gallery !== undefined">
        <div class="row mt-5" style="margin-left: 0; margin-right: 0">
          <div class="col-12">
            <gallery
              :gallery-images="config.gallery"
              :size="jsonConfig.gallery.size"
            ></gallery>
            <div class="d-flex justify-content-center">
              <a
                class="btn btn-primary slideshow-btn"
                href="#form"
                v-html="jsonConfig.gallery.button"
              ></a>
            </div>
          </div>
        </div>
      </div>

      <div
        class="container"
        v-if="
          jsonConfig.galleryv2 !== undefined &&
          (jsonConfig.galleryv2.top === undefined ||
            jsonConfig.galleryv2.top === false)
        "
      >
        <div class="row mt-5" style="margin-left: 0; margin-right: 0">
          <div class="col-12">
            <h2
              class="text-center pb-3"
              v-html="
                jsonConfig.galleryv2.header !== undefined
                  ? jsonConfig.galleryv2.header
                  : 'Viaturas em destaque!'
              "
            ></h2>
            <gallery2
              :gallery-images="jsonConfig.galleryv2.images"
              :size="jsonConfig.galleryv2.size"
            ></gallery2>
            <div class="d-flex justify-content-center">
              <a
                class="btn btn-primary slideshow-btn"
                href="#form"
                v-html="jsonConfig.galleryv2.button"
                v-if="jsonConfig.galleryv2.button_active"
              ></a>
            </div>
          </div>
        </div>
      </div>

      <div
        class="container"
        v-if="
          jsonConfig.galleryv3 !== undefined && jsonConfig.galleryv3.length > 0
        "
      >
        <div class="row mt-5" style="margin-left: 0; margin-right: 0">
          <div class="col-12 mb-3" v-for="gallery in jsonConfig.galleryv3">
            <h2
              class="text-center pb-3"
              v-html="
                gallery.header !== undefined
                  ? gallery.header
                  : 'Viaturas em destaque!'
              "
            ></h2>
            <gallery2
              :gallery-images="gallery.images"
              :size="gallery.size"
            ></gallery2>
            <div class="d-flex justify-content-center">
              <a
                class="btn btn-primary slideshow-btn"
                href="#form"
                v-html="gallery.button"
                v-if="gallery.button_active"
              ></a>
            </div>
          </div>
        </div>
      </div>

      <!-- Accordion Cards -->
      <div
        class="container container-card"
        v-if="jsonConfig.accordion_cards !== undefined"
      >
        <accordion-cards
          :config="jsonConfig.accordion_cards"
          :size="
            jsonConfig.accordion_cards.size !== undefined
              ? jsonConfig.accordion_cards.size
              : 3
          "
        ></accordion-cards>
      </div>

      <div
        class="container container-card"
        v-if="jsonConfig.accordion_cards_v2 !== undefined"
      >
        <accordion-cards
          v-for="(cards, i) in jsonConfig.accordion_cards_v2.cards"
          :key="i"
          :index="i"
          :config="cards"
          :size="cards.size !== undefined ? cards.size : 3"
        ></accordion-cards>
      </div>

      <!--        youtube start-->
      <div class="container" v-if="jsonConfig.youtube !== undefined">
        <app-video :json-config="jsonConfig"></app-video>
      </div>
    </div>

    <!--        bottom form -->
    <div
      class="container-fluid container-top bottom-form"
      v-if="formData !== null && formData.form_placement === 'bottom'"
    >
      <div class="container position">
        <div class="row">
          <!--					bottom form start-->
          <div
            class="col-12 right-side"
            id="form"
            :class="{
              'offset-lg-4 col-lg-4': !formData.fat_form,
              'offset-lg-3 offset-md-2 col-md-8 col-lg-6': formData.fat_form,
            }"
            v-observe-visibility="visibilityChanged"
          >
            <contact-form
              :config="config"
              :form-data="formData"
              :setup="setup"
              :json-config="jsonConfig"
              v-observe-visibility="changeVisible"
            ></contact-form>
          </div>
        </div>
      </div>
    </div>

    <!--        feed -->
    <div
      v-if="
        config.catalog !== null &&
        jsonConfig.catalog_feed !== undefined &&
        !catalogReady
      "
      class="text-center mt-3"
    >
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <div
      class="container-fluid container-catalog-feed"
      v-if="
        config.catalog !== null &&
        jsonConfig.catalog_feed !== undefined &&
        catalogReady
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <catalog-feed
              :catalog-data="catalogData"
              :module-config="jsonConfig.catalog_feed"
              :config="config"
              :fuel-type="fuelType"
              :makes="makes"
              :models="models"
              :addresses="requestAddresses"
              :prices="prices"
              :type="formData.form_placement"
            ></catalog-feed>
          </div>
        </div>
      </div>
    </div>

    <!--        navigate-out -->
    <div
      class="container-fluid container-navigate-out"
      id="navigate-out-bottom"
      v-if="
        jsonConfig.navigate_out_zone !== undefined &&
        jsonConfig.navigate_out_zone.position !== undefined
      "
    >
      <div class="container">
        <div class="row">
          <div
            class="col-12 d-md-flex justify-content-md-center text-center align-content-center"
          >
            <div
              class="pb-1 pt-1"
              v-html="jsonConfig.navigate_out_zone.text"
            ></div>
            <div class="pb-1 pt-2 pt-md-1 p-md-0 pl-0 pl-md-4">
              <a
                v-bind:href="jsonConfig.navigate_out_zone.href"
                target="_blank"
                rel="noopener"
                v-html="jsonConfig.navigate_out_zone.button_text"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--        after slideshow -->
    <div
      class="container-fluid container_after_form"
      id="after_form_slideshow"
      v-if="
        jsonConfig.container_after_form !== undefined &&
        jsonConfig.container_after_form.after_slideshow !== undefined &&
        jsonConfig.container_after_form.after_slideshow === true
      "
    >
      <div class="row">
        <div class="col-12">
          <div class="container">
            <div class="row">
              <div
                class="col-12 col-md-4 pb-3 pb-md-0"
                :class="{
                  'offset-md-4':
                    jsonConfig.container_after_form.bullets.length === 1,
                  'offset-md-2':
                    jsonConfig.container_after_form.bullets.length === 2 &&
                    i !== 1,
                  'col-md-6':
                    jsonConfig.container_after_form.size !== undefined &&
                    jsonConfig.container_after_form.size === 2,
                }"
                v-for="(item, i) of jsonConfig.container_after_form.bullets"
                :key="i"
                :index="i"
              >
                <div class="row">
                  <div class="col-3">
                    <img :src="config.bullets[i]" alt="" width="60px" />
                  </div>
                  <div class="col-9">
                    <h4>{{ item.title }}</h4>
                    <p>{{ item.text }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--        address -->
    <div
      class="container-fluid container-bottom mt-3"
      style="margin-bottom: 5rem"
      id="map"
    >
      <address-v2
        :config="config.footer_locations"
        v-if="addressStyle === 'v2' && config.footer_locations !== null"
        :assets="config.assets"
      ></address-v2>

      <div class="container">
        <div class="row">
          <div
            class="col-12 mt-3 mt-md-3 disclaimer mb-3 mb-md-3"
            v-if="setup.disclaimer_zone === 'bottom'"
          >
            <p id="privacy2" v-html="setPrivacyText()"></p>
            <p id="contract2">
              <strong v-html="campaignContract"></strong>
            </p>
            <p id="disclaimer2" v-html="setup.campaign_disclaimer"></p>
          </div>
        </div>
      </div>

      <address-v1
        :config="config.footer_locations"
        :setup="setup"
        v-if="addressStyle === 'v1' && config.footer_locations !== null"
      ></address-v1>
    </div>

    <!--       whatsapp -->
    <whatsapp
      v-if="whatsappActive"
      id="whatsapp-float"
      :config="config"
    ></whatsapp>

    <!--sticky button-->
    <div
      class="sticky-button d-none d-md-none"
      v-if="config.sticky_button === 1"
    >
      <div
        class="d-flex justify-content-start"
        :class="{
          'justify-content-around':
            (config.sticky_button_text !== null &&
              config.sticky_button_call_number !== null) ||
            (config.sticky_button_text === null &&
              config.sticky_button_call_number !== null &&
              !whatsappActive) ||
            (config.sticky_button_call_number === null &&
              config.sticky_button_text !== null &&
              !whatsappActive),
        }"
      >
        <a
          class="btn btn-primary mr-1 ml-1"
          href="#form"
          v-if="config.sticky_button_text !== null"
          v-html="config.sticky_button_text"
        ></a>
        <a
          class="btn btn-primary mr-1 ml-1"
          :href="'tel:' + config.sticky_button_call_number"
          @click="triggerCall()"
          v-if="config.sticky_button_call_number !== null"
          >Ligar</a
        >
      </div>
    </div>

    <!--footer -->
    <app-footer
      :config="config"
      :setup="setup"
      :seller-id="sellerId"
      :force-form-marketing-texts="formForceMarketingTexts"
      v-if="footerTemplate === 'base'"
    ></app-footer>
    <!--footer soauto vgrp-->
    <app-footer-soauto-vgrp
      :config="config"
      :setup="setup"
      :seller-id="sellerId"
      v-if="footerTemplate === 'soauto_vgrp'"
    ></app-footer-soauto-vgrp>

    <!-- footer Lubrigaz -->
    <app-footer-lubrigaz
      :config="config"
      :setup="setup"
      :seller-id="sellerId"
      v-if="footerTemplate === 'lubrigaz'"
    ></app-footer-lubrigaz>
    <app-footer-lubrigaz-skoda
      :config="config"
      :setup="setup"
      :seller-id="sellerId"
      v-if="footerTemplate === 'lubrigaz_skoda'"
    ></app-footer-lubrigaz-skoda>
    <app-footer-lubrisport
      :config="config"
      :setup="setup"
      :seller-id="sellerId"
      v-if="footerTemplate === 'lubrisport'"
    ></app-footer-lubrisport>

    <!--footer santogal-->
    <app-footer-santogal-renault
      :config="config"
      :setup="setup"
      :seller-id="sellerId"
      v-if="footerTemplate === 'renault_santogal'"
    ></app-footer-santogal-renault>

    <app-footer-santogal-dacia
      :config="config"
      :setup="setup"
      :seller-id="sellerId"
      v-if="footerTemplate === 'dacia_santogal'"
    ></app-footer-santogal-dacia>

    <modal :config="jsonConfig" v-if="jsonConfig.modal !== undefined"></modal>
    <form-modal
      :config="config"
      :form-data="formData"
      :setup="setup"
      :json-config="jsonConfig"
      v-if="formData !== null && formData.form_placement === 'modal'"
    ></form-modal>

    <debugger :config="config"></debugger>
  </div>
</template>

<script>
import "@/assets/styles/scss/base-template.scss";
import c2cSoautoMixin from "@/common/c2c-soauto-mixin";
import motorPriveMixin from "@/common/motor-prive-mixin";
import setFontMixin from "@/common/set-font-mixin";
import setStylesMixin from "@/common/set-styles-mixin";
import setWhatsappPosMixin from "@/common/set-whatsapp-pos-mixin";
import updateFlipCountdownMixin from "@/common/update-flipcountdown-mixin";
import Disclaimer from "@/components/shared/Disclaimer";
import WhatsappFloat from "@/components/shared/WhatsappFloat";
import router from "@/router";
import VueGtm from "@gtm-support/vue2-gtm";
import axios from "axios";
import * as moment from "moment";
import Vue from "vue";
import FlipCountdown from "vue2-flip-countdown";
import { mapState } from "vuex";

moment.locale("pt");

const Header = () =>
  import(/* webpackChunkName: "index" */ "@/components/Header");
const HeaderSantogalRenault = () =>
  import(/* webpackChunkName: "index" */ "@/components/HeaderSantogalRenault");
const HeaderSantogalDacia = () =>
  import(/* webpackChunkName: "index" */ "@/components/HeaderSantogalDacia");
const HeaderSoauto = () =>
  import(/* webpackChunkName: "index" */ "@/components/HeaderSoauto");
const HeaderSoautoVGRP = () =>
  import(/* webpackChunkName: "index" */ "@/components/HeaderSoautoVGRP");
const FooterSoautoVGRP = () =>
  import(/* webpackChunkName: "index" */ "@/components/FooterSoauto");
const HeaderSoautoAzul = () =>
  import(/* webpackChunkName: "index" */ "@/components/HeaderSoautoAzul");
const Footer = () =>
  import(/* webpackChunkName: "index" */ "@/components/Footer");
const FooterSantogalRenault = () =>
  import(/* webpackChunkName: "index" */ "@/components/FooterSantogalRenault");
const FooterLubrigaz = () =>
  import(/* webpackChunkName: "index" */ "@/components/FooterLubrigaz");
const FooterLubrigazSkoda = () =>
  import(/* webpackChunkName: "index" */ "@/components/FooterLubrigazSkoda");
const FooterLubrisport = () =>
  import(/* webpackChunkName: "index" */ "@/components/FooterLubrisport");
const FooterSantogalDacia = () =>
  import(/* webpackChunkName: "index" */ "@/components/FooterSantogalDacia");
const Video = () =>
  import(/* webpackChunkName: "index" */ "@/components/shared/Video");
const TabbedStaticContent = () =>
  import(
    /* webpackChunkName: "index" */ "@/components/shared/TabbedStaticContent"
  );
const TabbedStaticContentV2 = () =>
  import(
    /* webpackChunkName: "index" */ "@/components/shared/TabbedStaticContentV2"
  );
const TabbedStaticContentV3 = () =>
  import(
    /* webpackChunkName: "index" */ "@/components/shared/TabbedStaticContentV3"
  );
const ProductsCards = () =>
  import(/* webpackChunkName: "index" */ "@/components/shared/ProductsCards");
const ProductsCardsV2 = () =>
  import(/* webpackChunkName: "index" */ "@/components/shared/ProductsCardsV2");
const ProductsCardsV4 = () =>
  import(/* webpackChunkName: "index" */ "@/components/shared/ProductsCardsV4");
const ProductsCardsV5 = () =>
  import(/* webpackChunkName: "index" */ "@/components/shared/ProductsCardsV5");
const Modal = () =>
  import(/* webpackChunkName: "index" */ "@/components/shared/Modal");
const FormModal = () =>
  import(/* webpackChunkName: "index" */ "@/components/shared/FormModal");
const AccordionCards = () =>
  import(/* webpackChunkName: "index" */ "@/components/shared/AccordionCards");
const PhoneButtonCta = () =>
  import(/* webpackChunkName: "index" */ "@/components/shared/PhoneButtonCta");

export default {
  name: "Base",
  components: {
    Debugger: () =>
      import(/* webpackChunkName: "index" */ "@/components/Debugger"),
    "app-header": Header,
    "app-footer": Footer,
    "app-header-santogal-renault": HeaderSantogalRenault,
    "app-header-santogal-dacia": HeaderSantogalDacia,
    "app-header-soauto": HeaderSoauto,
    "app-header-soauto-vgrp": HeaderSoautoVGRP,
    "app-header-soauto-azul": HeaderSoautoAzul,
    "app-footer-santogal-renault": FooterSantogalRenault,
    "app-footer-lubrigaz": FooterLubrigaz,
    "app-footer-lubrigaz-skoda": FooterLubrigazSkoda,
    "app-footer-lubrisport": FooterLubrisport,
    "app-footer-santogal-dacia": FooterSantogalDacia,
    "app-footer-soauto-vgrp": FooterSoautoVGRP,
    disclaimer: Disclaimer,
    Gallery: () =>
      import(/* webpackChunkName: "index" */ "@/components/shared/Gallery"),
    Gallery2: () =>
      import(/* webpackChunkName: "index" */ "@/components/shared/Gallery2"),
    Slideshow: () =>
      import(/* webpackChunkName: "index" */ "@/components/shared/Slideshow"),
    SlideshowV2: () =>
      import(/* webpackChunkName: "index" */ "@/components/shared/SlideshowV2"),
    FlipCountdown,
    whatsapp: WhatsappFloat,
    CatalogFeed: () =>
      import(/* webpackChunkName: "index" */ "@/components/shared/CatalogFeed"),
    "tabbed-static-content": TabbedStaticContent,
    "tabbed-static-content-v2": TabbedStaticContentV2,
    "tabbed-static-content-v3": TabbedStaticContentV3,
    "products-cards-v2": ProductsCardsV2,
    "products-cards-v4": ProductsCardsV4,
    "products-cards-v5": ProductsCardsV5,
    AddressV1: () =>
      import(/* webpackChunkName: "index" */ "@/components/shared/AddressV1"),
    AddressV2: () =>
      import(/* webpackChunkName: "index" */ "@/components/shared/AddressV2"),
    ContactForm: () =>
      import(/* webpackChunkName: "index" */ "@/components/shared/ContactForm"),
    "app-video": Video,
    TopZone: () =>
      import(/* webpackChunkName: "index" */ "@/components/shared/TopZone"),
    phone_button_cta: PhoneButtonCta,
    "products-cards": ProductsCards,
    modal: Modal,
    "form-modal": FormModal,
    "accordion-cards": AccordionCards,
  },
  mixins: [
    setStylesMixin,
    setWhatsappPosMixin,
    setFontMixin,
    updateFlipCountdownMixin,
    motorPriveMixin,
    c2cSoautoMixin,
  ],
  props: {
    response: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // base settings
      config: Object,
      setup: Object,
      cssData: Object,
      jsonConfig: Object,
      formData: Object,

      gtmId: String,
      cookiesText: String,
      formForceMarketingTexts: Boolean,
      whatsappActive: Boolean,

      headerTemplate: "base",
      footerTemplate: "base",
      addressStyle: "null",

      sellerStands: Object,
      carouselData: 0,
      sellerId: String,
      logo: String,
      hasLogo: Boolean,
      logoAlt: String,
      addresses: String,
      headerConfig: Object,
      logo_left: String,
      logos_right: String,
      catalogReady: false,
      catalogData: Array,
      fuelType: Object,
      makes: Object,
      models: Array,
      requestAddresses: Object,
      prices: Array,
      campaignContract: String,
      isVisible: false,
      listingId: String,
      deadline: String,

      //flip-clock
      labels: {
        days: "dias",
        hours: "horas",
        minutes: "minutos",
        seconds: "segundos",
      },
    };
  },
  computed: mapState(["fatForm", "formPlacement"]),
  watch: {
    fatForm(newValue, oldValue) {
      this.formData.fat_form = newValue;
    },
    carouselData() {
      this.$refs.carousel.slideTo(this.carouselData);
    },
    formPlacement(newValue, oldValue) {
      this.formData.form_placement = newValue;
    },
  },
  updated() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    this.setData(this.response);
    this.setInitStyles();
    this.setFavIco(this.config.favicon !== null ? this.config.favicon : "");
    this.setTitle(
      this.config.meta_title !== null ? this.config.meta_title : ""
    );
    this.setFont();
    this.setCountDownFont();
    this.setMetas(this.config);
    this.deadline = this.updateFlipCountdown();

    this.$store.dispatch("getJsonConfig", this.jsonConfig);

    this.$eventBus.$on("set-cookies-gtm", (data) => {
      if (data) {
        this.setGtm();
        let sessionCookie = {
          accepted: true,
          domain: window.location.hostname,
        };
        this.$cookies.set(
          `${this.sellerId}-autoleads-cookie`,
          sessionCookie,
          "7d",
          "",
          ""
        );
      }
    });

    this.setPos();
    if (this.config.id === 678) {
      this.initMotorPrive();
    }

    // show c2c soauto
    if (this.config.id === 659 || this.config.id === 786) {
      this.setC2C();
    }
  },
  methods: {
    triggerCall() {
      if (
        this.$cookies.get(`${this.sellerId}-autoleads-cookie`) === null &&
        !this.setup.force_cookie_consent
      ) {
        this.setGtm();
      }
      let vm = this;
      setTimeout(() => {
        vm.$gtm.trackEvent(
          {
            event: "Phone_call", // Event type [default = 'interaction'] (Optional)
            category: "Phone_call",
            action: "Phone_call",
            label: "Phone_call",
            eventID: vm.$store.state.alid,
            value: 0,
            noninteraction: false, // Optional
          },
          500
        );
        window.alp.trackEvent({ event: "Phone_call" });
      });
    },
    changeVisible(isVisible, entry) {
      if (document.getElementsByClassName("sticky-button").length > 0) {
        if (!isVisible && document.body.clientWidth <= 768) {
          document
            .getElementsByClassName("sticky-button")[0]
            .setAttribute("style", "display:block !important;");
        }
        if (isVisible && document.body.clientWidth <= 768) {
          document
            .getElementsByClassName("sticky-button")[0]
            .setAttribute("style", "display:none !important;");
        }
      }
    },
    visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible;
    },
    setCampaignContractText() {
      if (this.config.campaign_contract !== null) {
        return this.config.campaign_contract;
      }

      return "";
    },
    setData(response) {
      if (process.env.NODE_ENV === "development") {
        console.log(response);
      }
      // Page Data
      this.config = response;
      this.setup = response.setup;
      this.cssData = response.css !== null ? response.css : {};
      this.formData = response.form !== null ? response.form : {};
      this.jsonConfig =
        response.json !== null && response.json.json_config !== null
          ? response.json.json_config
          : {};

      //trackers
      this.gtmId = this.setup.gtm_id !== null ? this.setup.gtm_id : "GTM-XXXXX";
      this.sellerId = this.setup.seller_id;

      //whatsapp
      this.whatsappActive = response.whatsapp_active === 1;
      //form
      this.formForceMarketingTexts =
        this.setup.form_force_marketing_texts === 1;
      //modules
      this.headerTemplate = this.setup.header_template;
      this.footerTemplate = this.setup.footer_template;
      this.addressStyle =
        this.config.address_style === null ? "null" : this.config.address_style;

      // catalog feed init
      if (
        this.config.catalog !== null &&
        this.jsonConfig !== null &&
        this.jsonConfig.catalog_feed !== undefined
      ) {
        this.fetchCatalogData(this.config.catalog.id);
      }

      if (
        this.$cookies.get(`${this.sellerId}-autoleads-cookie`) !== null &&
        this.$cookies.get(`${this.sellerId}-autoleads-cookie`).accepted &&
        this.$cookies.get(`${this.sellerId}-autoleads-cookie`).domain ===
          window.location.hostname
      ) {
        setTimeout(() => {
          this.setGtm();
        }, 500);
      }

      this.campaignContract = this.setCampaignContractText();
    },
    fetchCatalogData(id) {
      let vm = this;

      axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/ad-engine/landing-page/feed/${id}`,
          {
            params: {
              fuel_type_id: vm.$route.query.fuel_type_id
                ? vm.$route.query.fuel_type_id
                : "",
              car_make_id: vm.$route.query.car_make_id
                ? vm.$route.query.car_make_id
                : "",
              address_id: vm.$route.query.address_id
                ? vm.$route.query.address_id
                : "",
              model_id: vm.$route.query.model_id
                ? vm.$route.query.model_id
                : "",
              sale_price: vm.$route.query.sale_price
                ? vm.$route.query.sale_price
                : "",
              order: vm.$route.query.order ? vm.$route.query.order : "",
            },
          }
        )
        .then(function (response) {
          vm.catalogData = response.data.results.data;
          vm.fuelType = response.data.fuel_type;
          vm.makes = response.data.makes;
          vm.requestAddresses = response.data.addresses;
          vm.models = response.data.models;
          vm.prices = response.data.prices;
          vm.catalogReady = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    setMetas() {
      this.helpers.setMetas(this.config);
    },

    setGtm() {
      let ids;
      if (this.gtmId.indexOf(",") > -1) {
        ids = this.gtmId.split(",");
      } else {
        ids = this.gtmId;
      }
      Vue.use(VueGtm, {
        id: ids, // Your GTM ID
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: true, // Whether or not display console logs debugs (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        ignoredViews: [], // If router, you can exclude some routes name (case insensitive) (optional)
      });
    },

    setFavIco(icoUrl) {
      document.getElementById("favico").setAttribute("href", icoUrl);
    },

    setTitle(title) {
      document
        .getElementsByTagName("title")[0]
        .appendChild(document.createTextNode(title));
    },
    setCountDownFont() {
      if (this.jsonConfig.countdown !== undefined) {
        const s = document.createElement("link");
        s.rel = "stylesheet";
        s.href = this.jsonConfig.countdown.font;
        document.head.appendChild(s);
      }
    },
    setPrivacyText() {
      return this.helpers.setPrivacyText(this.setup);
    },
    validateDate() {
      return moment(this.deadline).isValid();
    },
  },
};
</script>
